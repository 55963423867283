// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react'
const theme = {
  colors: {
    primary: 'rgb(234 88 12)',
    black: 'rgb(9 9 11)'
  },
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true
  }
}

export default extendTheme(theme)