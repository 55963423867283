exports.components = {
  "component---src-components-brands-brand-jsx": () => import("./../../../src/components/brands/brand.jsx" /* webpackChunkName: "component---src-components-brands-brand-jsx" */),
  "component---src-components-watches-jsx": () => import("./../../../src/components/watches.jsx" /* webpackChunkName: "component---src-components-watches-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brands-jsx": () => import("./../../../src/pages/brands.jsx" /* webpackChunkName: "component---src-pages-brands-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-watch-watch-id-jsx": () => import("./../../../src/pages/watch/{watch.id}.jsx" /* webpackChunkName: "component---src-pages-watch-watch-id-jsx" */)
}

